.App {
  text-align: center;
}

body {
  /* background: linear-gradient(-45deg, #1f8fea, #05fefe, #0746bc); */

  /*  */
  background: black;
  color: white;

  background: rgb(14, 15, 18);
  font-family: monospace;
  /*  */
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: max-content;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
