@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

.transition-width {
  transition: width 0.5s ease; /* Adjust the duration (0.5s) and timing function (ease) as needed */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card:hover {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.card-back {
  transform: rotateY(180deg);
}

/*  */

h1 {
  overflow: hidden;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  color: white;
}

span {
  display: inline-block;
  animation: reveal 1.2s cubic-bezier(0.77, 0, 0.175, 1) 0.01s;
  animation-fill-mode: backwards;
}

@keyframes reveal {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
